import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterQuestionWithId } from '../../hooks/useFilterQuestions';
import {
  HardSkill,
  Question,
  Questionnaire,
  SoftSkill,
} from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import {
  NEW_RECRUITMENT_SLICE,
  StoreCustomizationData,
  StoreGeneralData,
  StoreJobPostData,
  StoreSuitabilityData,
  storeCustomizationData,
  storeEstimatedTimeData,
  storeGeneralData,
  storeHardSkillsData,
  storeJobPostData,
  storeQuestionsData,
  storeReset,
  storeSoftSkillsData,
  storeSuitabilityData,
} from './actions';
import { CREATE_JOB_POST, RETRIEVE_HARD_SKILLS } from './thunk';

interface RecruitmentState {
  company_id: string;
  name: string;
  job_area: string;
  sub_family: string;
  seniority: string;
  lang: number;
  type: 'job-post' | 'video-interview';
  soft_skills: SoftSkill[];
  questionnaires: Questionnaire[];
  questions: Question[];
  location: string;
  business_hours: string;
  cv_required: boolean;
  contract: string;
  description: string;
  notifications: boolean;
  presentation_interview_enabled: boolean;
  presentation_interview_question?: string;
  filter_questions_enabled: boolean;
  filter_questions: FilterQuestionWithId[];
  filter_questions_feedback_title: string;
  filter_questions_feedback_description: string;
  ats_job_post: string | null;
}

export interface CreatedState {
  id: number;
  name: string;
  onBoardingUrl: string | null;
  examUrl: string;
}

export interface NewRecruitmentState {
  loading: boolean;
  completed: boolean;
  hard_skills: HardSkill[];
  recruitment: Partial<RecruitmentState>;
  estimatedTime: number;
  created: CreatedState | null;
}

const NewRecruitmentInitialState: NewRecruitmentState = {
  loading: false,
  completed: false,
  hard_skills: [],
  recruitment: {},
  estimatedTime: 0,
  created: {} as CreatedState,
};

const slice = createSlice({
  name: NEW_RECRUITMENT_SLICE,
  initialState: NewRecruitmentInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(storeGeneralData, (state, action: PayloadAction<StoreGeneralData>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          name: action.payload.name,
          lang: action.payload.language,
          type: action.payload.type,
          job_area: action.payload.job_area,
          sub_family: action.payload.sub_family,
          seniority: action.payload.seniority,
          ats_job_post: action.payload.ats_job_post,
        },
      }))
      .addCase(storeJobPostData, (state, action: PayloadAction<StoreJobPostData>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          ...action.payload,
        },
      }))
      .addCase(storeSoftSkillsData, (state, action: PayloadAction<SoftSkill[]>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          soft_skills: [...action.payload],
        },
      }))
      .addCase(storeQuestionsData, (state, action: PayloadAction<Question[]>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          questions: [...action.payload],
        },
      }))
      .addCase(storeHardSkillsData, (state, action: PayloadAction<Questionnaire[]>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          questionnaires: [...action.payload],
        },
      }))
      .addCase(storeCustomizationData, (state, action: PayloadAction<StoreCustomizationData>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          presentation_interview_enabled: action.payload.presentation_interview_enabled,
          presentation_interview_question: action.payload.presentation_interview_question,
        },
      }))
      .addCase(storeSuitabilityData, (state, action: PayloadAction<StoreSuitabilityData>) => ({
        ...state,
        recruitment: {
          ...state.recruitment,
          filter_questions_enabled: action.payload.filter_questions_enabled,
          filter_questions_feedback_title: action.payload.filter_questions_feedback_title,
          filter_questions_feedback_description:
            action.payload.filter_questions_feedback_description,
          filter_questions: action.payload.filter_questions,
        },
      }))
      .addCase(storeEstimatedTimeData, (state, action: PayloadAction<number>) => ({
        ...state,
        estimatedTime: action.payload,
      }))
      .addCase(storeReset, (state, _) => ({
        ...state,
        completed: false,
        recruitment: {},
        created: null,
      }))
      .addCase(CREATE_JOB_POST.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(CREATE_JOB_POST.fulfilled, (state, action: PayloadAction<CreatedState>) => ({
        ...state,
        loading: false,
        completed: true,
        created: action.payload,
      }))
      .addCase(CREATE_JOB_POST.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_HARD_SKILLS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(RETRIEVE_HARD_SKILLS.fulfilled, (state, action: PayloadAction<HardSkill[]>) => ({
        ...state,
        loading: false,
        hard_skills: [...action.payload],
      }))
      .addCase(RETRIEVE_HARD_SKILLS.rejected, (state, _) => ({
        ...state,
        loading: false,
      })),
});

export default slice.reducer;
