import { createAction } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import { FilterQuestionWithId } from '../../hooks/useFilterQuestions';
import {
  Question,
  Questionnaire,
  SoftSkill,
} from '../../models/ThunksInterfaces/retrieveAvailableSkills';

export const NEW_RECRUITMENT_SLICE = 'New Recruitment';

// TODO: move to better place than here...but where?
export interface StoreGeneralData {
  name: string;
  language: number;
  type: 'job-post' | 'video-interview';
  job_area: string;
  sub_family: string;
  seniority: string;
  ats_job_post: string | null;
}

// TODO: move to better place than here...but where?
export interface StoreJobPostData {
  location: string;
  business_hours: string;
  cv_required: boolean;
  contract: string;
  description: string;
  notifications: boolean;
}

export interface StoreCustomizationData {
  presentation_interview_enabled: boolean;
  presentation_interview_question?: string;
}
export interface StoreSuitabilityData {
  filter_questions_enabled: boolean;
  filter_questions?: FilterQuestionWithId[];
  filter_questions_feedback_title?: string;
  filter_questions_feedback_description?: string;
}

export const storeGeneralData = createAction<StoreGeneralData>(ActionsEnum.STORE_GENERAL_DATA);
export const storeJobPostData = createAction<StoreJobPostData>(ActionsEnum.STORE_JOB_POST_DATA);
export const storeSoftSkillsData = createAction<SoftSkill[]>(ActionsEnum.STORE_SOFT_SKILLS_DATA);
export const storeQuestionsData = createAction<Question[]>(ActionsEnum.STORE_QUESTIONS_DATA);
export const storeHardSkillsData = createAction<Questionnaire[]>(
  ActionsEnum.STORE_HARD_SKILLS_DATA
);
export const storeCustomizationData = createAction<StoreCustomizationData>(
  ActionsEnum.STORE_CUSTOMIZATION_DATA
);
export const storeSuitabilityData = createAction<StoreSuitabilityData>(
  ActionsEnum.STORE_SUITABILITY_DATA
);
export const storeEstimatedTimeData = createAction<number>(ActionsEnum.STORE_ESTIMATED_TIME_DATA);
export const storeReset = createAction(ActionsEnum.NEW_RECRUITMENT_RESET);
