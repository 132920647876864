import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { HardSkill, SoftSkill } from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { http } from '../../utils/http';

export const RETRIEVE_AVAILABLE_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_SKILLS,
  async ({
    companyId,
    languageId,
  }: {
    companyId?: number;
    languageId?: number;
  }): Promise<SoftSkill[]> => {
    let soft_skills: SoftSkill[];
    try {
      const config: AxiosRequestConfig = { method: 'GET' };
      if (companyId) {
        config.params = { ...config.params, companyId };
      }
      if (languageId) {
        config.params = { ...config.params, languageId };
      }
      const response = await http(BACKEND_ENDPOINTS.RETRIEVE_AVAILABLE_SKILLS, config);
      soft_skills = response.data.data.softSkills;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return soft_skills;
  }
);

export const RETRIEVE_ALL_HARD_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_ALL_HARD_SKILLS,
  async (): Promise<HardSkill[]> => {
    let hard_skills: HardSkill[];
    try {
      const config: AxiosRequestConfig = { method: 'GET' };
      const response = await http(BACKEND_ENDPOINTS.RETRIEVE_HARD_SKILLS, config);
      hard_skills = response.data.data.hard_skills;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return hard_skills;
  }
);
