export enum ApplicationsEnum {
  RETRIEVE_JOBPOST_APPLICATIONS = 'Applications/Retrieve Job Post Applications List',
  RETRIEVE_APPLICATION = 'Applications/Retrieve Specific Application',
  UPDATE_APPLICATION = 'Applications/Update Application',
  DELETE_APPLICATION = 'Applications/Delete Application',
  UPDATE_APPLICATION_STATUS = 'Applications/Update Application Status',
  SUBMIT_APPLICATION_RATING = 'Applications/Submit HR Rating',
  DELETE_APPLICATION_RATING = 'Applications/Delete HR Rating',
  RETRIEVE_JOB_POST_STATES = 'Applications/Retrieve Job Post States',
  SUBMIT_APPLICATION_STATE = 'Applications/Submit Application State',
  UPDATE_APPLICATION_STATE = 'Applications/Update Application State',
  DELETE_APPLICATION_STATE = 'Applications/Delete Application State',
  SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW = 'Applications/Single Email Invite Candidate To VideoInterview',
  BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW = 'Applications/Bulk Email Invite To VideoInterview',
  BULK_SMS_INVITE_TO_VIDEOINTERVIEW = 'Applications/Bulk Sms Invite To VideoInterview',
  BULK_APPLICATIONS_PHONE_UPDATE = 'Applications/Bulk application phones update',
  SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW = 'Applications/Single Sms Invite Candidate To VideoInterview',
  SUBMIT_CV_RATING = 'Applications/Submit CV Rating',
  SUBMIT_CV_UPLOAD = 'Applications/Submit CV Upload',
  UPDATE_JOB_POST_STATUS = 'Applications/Update Job Post Status',
  SELECT_APPLICATION_SKILL = 'Applications/Select Application Skill',
  SELECT_APPLICATION_QUESTION = 'Applications/Select Application Question',
  RESET_SELECTED_JOB = 'Application/Reset selected Job',
  RESET_SELECTED_APPLICATION = 'Application/Reset selected Application',
  RESET_NOT_INVITED_BY_SMS = 'Application/Reset not invited by SMS',
  EXPORT_CANDIDATES_TO_EXCEL = 'Application/Export Candidates To Excel',
  REQUEST_CANDIDATE_REPORT = 'Application/Request Candidate Report',
  DOWNLOAD_CANDIDATE_REPORT_LIGHT = 'Application/Download Candidate Report Light',
  DOWNLOAD_CANDIDATE_REPORT_PRO = 'Application/Download Candidate Report Pro',
  SUBMIT_PRESENTATION_RATING = 'Application/Submit Video Presentation Rating',
  SUBMIT_EMAIL_FEEDBACK = 'Application/Submit Email Feedback',
}
