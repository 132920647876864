import { FunctionComponent, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import { Routes, canNavigate } from '../../../constants/routes';
import { CompanyDbModel } from '../../../models/company.model';
import { getWasSaml } from '../../../store/auth/selectors';
import { useAppSelector } from '../../../store/store';
const PrivateRouter = lazy(() => import('./PrivateRouter'));
const PublicRouter = lazy(() => import('./PublicRouter'));

interface UnAuthenticatedRouteProps extends RouteProps {
  component: FunctionComponent<RouteComponentProps>;
  isLogged: boolean;
  companies?: CompanyDbModel[];
}

interface AuthenticatedRouteProps extends RouteProps {
  component: FunctionComponent<RouteComponentProps>;
  isLogged: boolean;
  permissions?: string[];
}

const UnAuthenticatedRoute = ({ component: Component, ...rest }: UnAuthenticatedRouteProps) => {
  const { companies, isLogged } = { ...rest };

  let company_id: number;

  if (companies) {
    company_id = companies[0].id;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Redirect to={`/${company_id}/${Routes.CANDIDATES}`} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AuthenticatedRoute = ({ component: Component, ...rest }: AuthenticatedRouteProps) => {
  const { path, isLogged, permissions } = { ...rest };

  const wasSaml = useAppSelector(getWasSaml);

  if (permissions && !canNavigate(path as string, permissions)) {
    return <Redirect to={Routes.UNAUTHORIZED} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect to={wasSaml ? `${Routes.PUBLIC}/${Routes.LOGIN}?saml=true` : `${Routes.PUBLIC}/${Routes.LOGIN}`} />
        )
      }
    />
  );
};

const Router = ({
  isLogged,
  companies,
  permissions,
}: {
  isLogged: boolean;
  companies?: CompanyDbModel[];
  permissions?: string[];
}) => {
  return (
    <Switch>
      {/* Public routes */}
      <UnAuthenticatedRoute
        isLogged={isLogged}
        companies={companies}
        path={Routes.PUBLIC}
        component={PublicRouter}
      />
      {/* Protected routes */}
      <AuthenticatedRoute
        isLogged={isLogged}
        permissions={permissions}
        path={Routes.BASE}
        component={PrivateRouter}
      />
      {/* Handle unmatched routes */}
      <Route path="*">
        <Redirect to={`${Routes.PUBLIC}/${Routes.LOGIN}`} />
      </Route>
    </Switch>
  );
};

export default Router;
