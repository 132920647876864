import { createAction } from '@reduxjs/toolkit';
import { ApplicationsEnum } from './enums/actions';

export const APPLICATIONS_SLICE = 'Applications';

export const selectApplicationSkill = createAction<number | null>(
  ApplicationsEnum.SELECT_APPLICATION_SKILL
);
export const selectApplicationQuestion = createAction<number>(
  ApplicationsEnum.SELECT_APPLICATION_QUESTION
);
export const resetSelectedJob = createAction(ApplicationsEnum.RESET_SELECTED_JOB);
export const resetSelectedApplication = createAction(ApplicationsEnum.RESET_SELECTED_APPLICATION);
export const resetNotInvitedBySms = createAction(ApplicationsEnum.RESET_NOT_INVITED_BY_SMS);
